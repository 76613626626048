// Light Theme
// By:
.light[app-theme] {
  //
  --background-secondary: #F0F3F3;
  --tile-bg: #FFFFFF;
  --tile-secondary-bg: #F4F6F6;
  --nft-section-bg: #ebf5ff;
  --text-secondary: #545656;
  --text-muted: #8D8F8F;
  --text-inverted: #F0F3F3;
  --text-inverted-muted: #9B9E9F;
  --highlight: #005BFF;
  --highlight-secondary: #E5F2FF;
  --error: #FF6939;
  --error-light: #FFECE7;
  --success: #00940f;

  --background: #F7F9F9;
  --text: #000;
  --border: #DDD;
  --borderheavy: #CAD6D7;
  --feed-container-hover: #f0f0f0;
  --feed-list-item-hover: #f0f0f0;


  --grey: #555555;
  --secalt: #eee;
  --norm: #222;
  --formbg: #FFFFFF;
  --link: #005BFF;
  --hover: #0056b3;
  --mborder: #E5E5E5;
  --filter: ;
  --unread: #e7effe;
  --topbar: #2e2e2e;
  --cblue: #0058F7;
  --cred: #fe3537;
  --cgreen: #19B028;
  --button: #0058F7;
  --loading: #777777;
  --glow: #00F9FC;
}
